import React from 'react';

const SettingsPage = () => {
  return (
    <h1 className='text-3xl font-bold'>
      This is settings page..!!
    </h1>
  )
}

export default SettingsPage;

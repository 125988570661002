import React from 'react';

const DashboardPage = () => {
  return (
    <h1 className='text-3xl font-bold'>
      This is dashboard page..!!
    </h1>
  )
}

export default DashboardPage;

import * as React from 'react'

import { cn } from 'lib/utils'

const Input = React.forwardRef(({ className, type, error, ...props }, ref) => {
  const hasError = error && error.length > 0;

  return (
    <>
      <input
        type={type}
        className={cn(
          'flex h-9 w-full rounded-sm border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />

      {hasError && (
        <p className="text-xs text-red-500">{error}</p>
      )}
    </>
  );
})
Input.displayName = 'Input'

export { Input }
